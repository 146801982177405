/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 8:00 PM */

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-Regular.eot');
  src        : local('Akrobat Regular'), local('Akrobat-Regular'),
    url('./Akrobat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-Regular.woff') format('woff'),
    url('./Akrobat-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-ExtraLight.eot');
  src        : local('Akrobat ExtraLight'), local('Akrobat-ExtraLight'),
    url('./Akrobat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-ExtraLight.woff') format('woff'),
    url('./Akrobat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-SemiBold.eot');
  src        : local('Akrobat SemiBold'), local('Akrobat-SemiBold'),
    url('./Akrobat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-SemiBold.woff') format('woff'),
    url('./Akrobat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-Light.eot');
  src        : local('Akrobat Light'), local('Akrobat-Light'),
    url('./Akrobat-Light.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-Light.woff') format('woff'),
    url('./Akrobat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-Black.eot');
  src        : local('Akrobat Black'), local('Akrobat-Black'),
    url('./Akrobat-Black.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-Black.woff') format('woff'),
    url('./Akrobat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-Bold.eot');
  src        : local('Akrobat Bold'), local('Akrobat-Bold'),
    url('./Akrobat-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-Bold.woff') format('woff'),
    url('./Akrobat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-Thin.eot');
  src        : local('Akrobat Thin'), local('Akrobat-Thin'),
    url('./Akrobat-Thin.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-Thin.woff') format('woff'),
    url('./Akrobat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style : normal;
}

@font-face {
  font-family: 'Akrobat';
  src        : url('./Akrobat-ExtraBold.eot');
  src        : local('Akrobat ExtraBold'), local('Akrobat-ExtraBold'),
    url('./Akrobat-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./Akrobat-ExtraBold.woff') format('woff'),
    url('./Akrobat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style : normal;
}