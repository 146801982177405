@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '@qpokychuk/ripple/index.css';
  @import 'swiper/scss';
  @import 'air-datepicker/air-datepicker.css';
}

@import 'swiper/scss/pagination';
@import '@qpokychuk/inter/index.css';
@import '../fonts/Akrobat/index.css';
@import '../ui/ui-core';
@import './style';