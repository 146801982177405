@import 'functions';

:root {
  // theme colors
  --primary-rgb : #{cl(#2253C8)};
  --sec-rgb     : #{cl(#ED1C24)};
  --blue-rgb    : #{cl(#2173E5)};
  --red-rgb     : #{cl(#c0392b)};
  --green-rgb   : #{cl(#27ae60)};
  --yellow-rgb  : #{cl(#FECB29)};
  --orange-rgb  : #{cl(#E1952B)};

  // colors
  --bg1-rgb     : #{cl(#ededed)};
  --bg2-rgb     : #{cl(#F5F7FB)};
  --bg3-rgb     : #{cl(#fff)};
  --default-rgb : #{cl(#383838)};
  --white-rgb   : #{cl(#fff)};
  --black-rgb   : #{cl(#000)};

  // variables
  --bg1    : rgb(var(--bg1-rgb));
  --bg2    : rgb(var(--bg2-rgb));
  --bg3    : rgb(var(--bg3-rgb));
  --default: rgb(var(--default-rgb));

}

body {
  display       : flex;
  flex-direction: column;
  min-height    : 100vh;
  background    : theme('colors.l2');
  font-family   : theme('fontFamily.base');
  color         : theme('colors.default');

  &.menu-opened {
    overflow: hidden;
  }
}

[data-theme="dark"] {
  --bg1-rgb     : #{cl(#181818)};
  --bg2-rgb     : #{cl(#282828)};
  --bg3-rgb     : #{cl(#323232)};
  --default-rgb : #{cl(#fff)};

}

@media (hover) {
  // temp

  .btn-white:hover,
  .btn-white:is(.btn-group:hover .btn),
  .btn-white:is(.btn-peer:hover~.btn) {
    --tw-btn-color: #fff;
  }
}

@layer components {
  .active-enabled:not(:is(:is(.toggle-active, .tab-active, .active) .active-enabled)) {
    display: none !important;
  }

  .active-disabled:is(:is(.toggle-active, .tab-active, .active) .active-disabled) {
    display: none !important;
  }

  .is-active-disabled:is(.toggle-active, .active) {
    display: none;
  }

  .is-active-enabled:not(:is(.toggle-active, .active)) {
    display: none;
  }

  .absolute-fit {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
  }

  .hover-underline {
    position: relative;

    &::before {
      content   : '';
      position  : absolute;
      top       : 100%;
      left      : 0;
      width     : 100%;
      height    : 2px;
      background: theme('colors.primary');
      transform : scaleX(0);
      transition: .25s ease;
    }

    @media(hover) {
      // temp

      &-wrapper:hover &::before,
      &:hover::before {
        transform: scaleX(1);
      }
    }
  }

  .btn {
    border-radius: var(--tw-btn-size);

    &-fill {
      background:
        radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), var(--tw-btn-color);

      @media(hover) {
        &:hover {
          background:
            radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), var(--tw-btn-color);
        }
      }
    }

    &-text {
      border-radius: initial;
    }
  }

  .rounded-section {
    @media (min-width: theme('screens.lg')) {
      width        : calc(100% - 96px);
      margin       : 0 auto;
      padding      : 0 calc(50% - var(--section-width) / 2 - 48px);
      border-radius: 48px;
    }
  }

  .title {
    font-family   : theme('fontFamily.alt');
    font-size     : 62px;
    font-weight   : 900;
    line-height   : calc(74 / 62);
    text-transform: uppercase;

    @media (max-width: theme('screens.lg')) {
      font-size: 42px;
    }
  }

  .p-section {
    padding-top   : 72px;
    padding-bottom: 80px;

    @media (max-width: theme('screens.sm')) {
      padding-top   : 40px;
      padding-bottom: 40px;
    }
  }

  .input {
    padding-top   : 0;
    padding-bottom: 0;
    border-radius : 10px;
    color         : theme('colors.black');
  }
}

.header {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  position       : sticky;
  top            : 0;
  padding-top    : 20px;
  padding-bottom : 12px;
  background     : theme('colors.l3');
  z-index        : 3;

  @media (max-width: theme('screens.lg')) {
    padding-top   : 12px;
    padding-bottom: 12px;
  }

  &-logo {
    @media (max-width: theme('screens.lg')) {
      height: 36px;
    }

    img {
      max-height: 100%;
    }
  }
}

.nav {
  display       : flex;
  padding-top   : 8px;
  padding-bottom: 20px;
  background    : theme('colors.l3');
  gap           : 12px;
}

.started {
  display       : flex;
  align-items   : center;
  position      : relative;
  // top        : 60px;
  height        : 710px;
  overflow      : hidden;
  background    : linear-gradient(180deg, rgba(12, 22, 99, 0) 46.7%, #0C1663 90.59%),
    url("../img/started-bg.jpg") no-repeat top / 100% auto, #0C1663;
  color: #fff;

  &::after {
    content       : '';
    position      : absolute;
    inset         : 0;
    background    : linear-gradient(180deg, rgba(12, 22, 99, 0) 46.7%, #0C1663 90.59%);
    pointer-events: none;

    @media (max-width: theme('screens.md')) {
      top: 75%;
    }
  }



  @media (max-width: theme('screens.lg')) {
    align-items   : stretch;
    flex-direction: column;
    height        : auto;

    padding-top   : 40px;
    padding-bottom: 150px;
    background    :
      linear-gradient(40.18deg, rgba(22, 69, 188, 0) 45.07%, #1A8AFF 88.39%), #0C1663;
  }

  @media (max-width: theme('screens.sm')) {
    // position      : relative;
    // top           : auto;
    padding-bottom: 330px;
  }

  &-wrapper {
    padding-bottom: 48px;
    background    : #0C1663;

    @media (max-width: theme('screens.lg')) {
      padding-bottom: 0;
    }
  }

  &-image {
    position      : absolute;
    top           : 0;
    left          : 32%;
    height        : 100%;
    pointer-events: none;

    @media (min-width: 2300px) {
      left: 42%;
    }

    @media (max-width: theme('screens.lg')) {
      left: 35%;
    }

    @media (max-width: theme('screens.md')) {
      top   : auto;
      right : 0;
      bottom: 0;
      left  : auto;
      height: 325px;
    }

    img {
      max-width : none;
      max-height: 100%;
    }
  }

  &-content {
    position: relative;
    z-index : 1;
  }

  &-title {
    // temp
  }
}

.advantages {
  &__text {
    background: linear-gradient(180deg, #fff 0%, #9699B4 100%);

    -webkit-text-fill-color: transparent;

    @apply bg-clip-text;
  }
}

.directions {
  &__item {
    position     : relative;
    min-height   : 370px;
    overflow     : hidden;
    background   : #fff;
    border-radius: 32px;

    &::before {
      content      : '';
      position     : absolute;
      top          : -183px;
      left         : -129px;
      width        : 500px;
      height       : 500px;
      background   : #F7F7F7;
      border-radius: 50%;
    }

    @media (max-width: theme('screens.sm')) {
      min-height: auto;
    }
  }
}


.about {
  position      : relative;
  overflow      : hidden;
  padding-top   : 72px;
  padding-bottom: 72px;
  background    :
    radial-gradient(50% 317.96% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(241, 237, 255, 0.4) 53.16%, #DED5FE 100%), #F9F8FD;

  @media (max-width: theme('screens.lg')) {
    align-items   : stretch;
    flex-direction: column;
    height        : auto;
    padding-top   : 40px;
    padding-bottom: 40px;
  }

  @media (max-width: theme('screens.sm')) {
    padding-bottom: 500px;
  }

  &-image {
    position : absolute;
    top      : 50%;
    left     : calc(50% - 340px);
    height   : max-content;
    transform: translateY(-50%);

    @media (max-width: theme('screens.lg')) {
      top      : auto;
      bottom   : -2px;
      left     : 70%;
      height   : 500px;
      transform: translateX(-50%);
    }

    @media (max-width: theme('screens.sm')) {
      left  : 50%;
      height: 500px;
    }

    img {
      max-width : none;
      max-height: 100%;
    }
  }

  &-content {
    position: relative;
  }
}

.service-item {
  display       : flex;
  flex-direction: column;
  overflow      : hidden;
  background    : theme('colors.l3');
  border-radius : 32px;
  box-shadow    : 0 4px 20px 0 rgba(0, 0, 0, 0.06);

  &-content {
    display       : flex;
    align-items   : flex-start;
    flex-direction: column;
    flex-grow     : 1;
    padding       : 24px;

    @media (max-width: theme('screens.sm')) {
      padding: 16px;
    }
  }
}

.process-item {
  display       : flex;
  flex-direction: column;
  border-radius : 32px;

  &-1 {
    background: theme('colors.l2');
  }

  &-2 {
    padding-top: 20px;
    background : #3FA1CB;
    color      : #fff;
  }

  &-3 {
    padding-top: 20px;
    background : #0C1663;
    color      : #fff;
  }

  &-4 {
    background: #E1952B;
    color     : #fff;
  }

  &-content {
    display       : flex;
    align-items   : flex-start;
    flex-direction: column;
    flex-grow     : 1;
    padding       : 24px;

    @media (max-width: theme('screens.sm')) {
      padding: 16px;
    }
  }
}

.consalting-item {
  display       : flex;
  flex-direction: column;
  padding       : 4px;
  border-radius : 32px;
  box-shadow    : 0 4px 20px 0 rgba(0, 0, 0, 0.06);

  &-header {
    display                  : flex;
    align-items              : center;
    padding                  : 40px 20px;
    border-start-start-radius: inherit;
    border-start-end-radius  : inherit;
    gap                      : 12px;
  }

  &-content {
    display       : flex;
    align-items   : flex-start;
    flex-direction: column;
    flex-grow     : 1;
    padding       : 24px;

    @media (max-width: theme('screens.sm')) {
      padding: 16px;
    }
  }
}

.reviews {
  &-tabs {
    display      : flex;
    position     : relative;
    overflow     : auto;
    margin-bottom: 48px;
    border-bottom: 1px solid theme('colors.default / 20%');
    white-space  : nowrap;

    @media (max-width: theme('screens.md')) {
      margin-right : -15px;
      margin-left  : -15px;
      padding-right: 15px;
      padding-left : 15px;
      gap          : 40px;
    }

    @media (max-width: theme('screens.sm')) {
      margin-bottom: 24px;
    }

    &-plate {
      position  : absolute;
      bottom    : 0;
      height    : 2px;
      background: theme('colors.sec');
      transition: .25s ease;
    }
  }

  &-tab {
    flex-grow     : 1;
    padding-top   : 16px;
    padding-bottom: 16px;
    font-family   : theme('fontFamily.alt');
    font-size     : 24px;
    font-weight   : 700;
    text-align    : center;
    text-transform: uppercase;

    @media (max-width: theme('screens.lg')) {
      padding-top   : 12px;
      padding-bottom: 12px;
      font-size     : 18px;
    }

    &.tab-active {
      font-weight: 900;
    }
  }
}

.reviews-item {
  padding      : 8px;
  background   : theme('colors.l3');
  border       : 1px solid theme('colors.default / 10%');
  border-radius: 32px;
  box-shadow   : 0 4px 20px 0 rgba(0, 0, 0, 0.06);

  &-image {
    overflow     : hidden;
    border-radius: 24px;
  }
}

.video {
  &-block {
    overflow     : hidden;
    border-radius: 48px;

    @media (max-width: theme('screens.md')) {
      border-radius: 32px;
    }
  }

  &-content {
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-direction : column;
    color          : #fff;
  }
}

.pray {
  @media (max-width: theme('screens.md')) {
    background: theme('colors.l3');
  }

  &-block {
    display      : flex;
    align-items  : center;
    padding      : 32px;
    padding-left : 56px;
    background   : theme('colors.l3');
    border-radius: 48px;
    gap          : 56px;

    @media (max-width: theme('screens.lg')) {
      align-items   : stretch;
      flex-direction: column;
      padding       : 32px;
    }

    @media (max-width: theme('screens.md')) {
      padding      : 0;
      background   : none;
      border-radius: 0;
    }
  }

  &-review {
    display       : flex;
    flex-direction: column;
    flex-shrink   : 0;
    width         : 456px;
    padding       : 40px 40px 32px;
    background    :
      linear-gradient(148.19deg, theme('colors.l3 / 80%') 0%, theme('colors.l3 / 0%') 100%), theme('colors.primary / 20%');
    border-radius: 32px;

    @media (max-width: theme('screens.lg')) {
      width: 100%;
    }

    @media (max-width: theme('screens.sm')) {
      padding      : 24px 16px 16px;
      border-radius: 28px;
    }
  }
}

.contact {
  background:
    linear-gradient(80.97deg, rgba(22, 69, 188, 0) 0%, #375BD1 100%), #0C1663;
  color: #fff;

  &-grid {
    display: flex;

    @media (max-width: theme('screens.lg')) {
      display              : grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &-item {
    display      : flex;
    align-items  : center;
    padding      : 32px 24px;
    background   : rgba(255, 255, 255, 0.1);
    border-radius: 32px;
    transition   : .15s;
    gap          : 12px;

    @media (max-width: theme('screens.lg')) {
      align-items   : flex-start;
      flex-direction: column;
    }

    @media (max-width: theme('screens.sm')) {
      padding      : 24px 16px;
      border-radius: 16px;
    }

    @media(hover) {
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}

.map {
  height: 530px;

  @media (max-width: theme('screens.sm')) {
    height: 432px;
  }
}

.dialog {
  max-width    : 100%;
  background   : theme('colors.l3');
  border-radius: 48px;

  @media (max-width: theme('screens.sm')) {
    border-radius: 24px;
  }
}

.dialog-policy {
  width  : 970px;
  padding: 40px;

  @media (max-width: theme('screens.lg')) {
    padding      : 32px 16px;
    border-radius: 24px;
  }
}

.dialog-info {
  width: 320px;

  &-image {
    position                 : relative;
    height                   : 178px;
    overflow                 : hidden;
    border-start-start-radius: inherit;
    border-start-end-radius  : inherit;
  }
}

.dialog-form {
  display : flex;
  width   : 1120px;
  overflow: hidden;

  @media (max-width: theme('screens.lg')) {
    width: 600px;
  }

  &-content {
    flex-grow: 1;
    padding  : 44px;

    @media (max-width: theme('screens.sm')) {
      padding: 32px 16px;
    }
  }

  &-image {
    flex-shrink: 0;
    width      : 610px;

    @media (max-width: theme('screens.lg')) {
      display: none;
    }
  }
}

.dialog-service {
  display              : grid;
  grid-template-columns: 1fr 1fr;
  width                : 1120px;
  overflow             : hidden;
  padding              : 16px;
  gap                  : 16px;

  @media (max-width: theme('screens.lg')) {
    grid-template-columns: 1fr;
    overflow             : hidden;
    padding              : 0;
    gap                  : 0;
  }

  &-content {
    padding      : 32px;
    padding-right: 10px;
    background   : #EEF3FA;
    border-radius: 40px 0 0 40px;

    @media (max-width: theme('screens.lg')) {
      border-radius: 0;
    }

    @media (max-width: theme('screens.sm')) {
      padding: 32px 16px;
    }
  }

  &-form {
    padding: 32px 48px;
    background:
      linear-gradient(68.9deg, rgba(22, 69, 188, 0) 0%, #1A8AFF 81.7%), #0C1663;
    border-radius: 0 40px 40px 0;
    color        : #fff;

    @media (max-width: theme('screens.lg')) {
      border-radius: 0;
    }

    @media (max-width: theme('screens.sm')) {
      padding: 32px 16px;
    }
  }


}

.dialog-inside .dialog-service-form {
  background:
    url("../img/dialog-inside-bg.jpg") no-repeat center / cover,
    linear-gradient(225deg, #37C3FF 0%, #3F63FF 22.94%, #CF41FF 66%, #0C1663 92.09%);

}

.dialog-payment {
  width  : 584px;
  padding: 48px 48px 64px;

  @media (max-width: theme('screens.sm')) {
    padding: 32px 16px;
  }
}

.dialog-oferta {
  width  : 1120px;
  padding: 48px 48px 64px;

  @media (max-width: theme('screens.sm')) {
    padding: 32px 16px;
  }
}

.menu {
  display       : flex;
  flex-direction: column;
  position      : fixed;
  top           : 0;
  left          : 0;
  width         : 425px;
  max-width     : 100%;
  height        : 100%;
  overflow      : auto;
  padding-bottom: 40px;
  background    : theme('colors.l3');
  transition    : .25s ease;
  z-index       : theme('zIndex.3');

  &:not(.toggle-active) {
    transform: translateX(-100%);
  }

  &-shadow {
    position  : fixed;
    inset     : 0;
    background: rgba(0, 0, 0, 0.5);
    transition: .25s ease;
    z-index   : calc(theme('zIndex.3') - 1);
  }

  &:not(.toggle-active)+&-shadow {
    opacity       : 0;
    pointer-events: none;
  }
}

[data-animation] {
  opacity: 0;
}

.air-datepicker {
  --adp-cell-background-color-selected      : theme('colors.sec');
  --adp-cell-background-color-selected-hover: theme('colors.sec');
  --adp-color-current-date                  : theme('colors.sec');
  --adp-day-name-color                      : theme('colors.yellow');
}

.air-datepicker-global-container {
  z-index: 1100;
}

.tabs {
  display      : flex;
  border-bottom: 1px solid theme('colors.default / 10%');
}

.tab {
  padding-top   : 20px;
  padding-bottom: 20px;
  font-family   : theme('fontFamily.alt');
  font-size     : 24px;
  font-weight   : 700;
  text-transform: uppercase;

  @media (max-width: theme('screens.sm')) {
    padding-top   : 12px;
    padding-bottom: 12px;
    font-size     : 18px;

  }

  &.tab-active {
    border-bottom: 2px solid theme('colors.sec');
    font-weight  : 900;
  }
}

.not-found {
  display        : flex;
  align-items    : center;
  justify-content: center;
  flex-direction : column;
  padding-top    : 64px;
  padding-bottom : 64px;
}

.counter {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding-top    : 86px;
  padding-bottom : 86px;
  background     : theme('colors.l3');
  gap            : 24px;

  @media (max-width: theme('screens.lg')) {
    justify-content: center;
    flex-wrap      : wrap;
    padding-top    : 32px;
    padding-bottom : 32px;
  }

  &-item {
    position      : relative;
    width         : 348px;
    height        : 348px;
    background    : linear-gradient(225deg, #3A5DD2 27.84%, #5091E3 97.65%);
    border-radius : 50%;
    outline       : 4px solid #fff;
    outline-offset: -12px;
    color         : #fff;

    @media (max-width: theme('screens.lg')) {
      width : 330px;
      height: 330px;
    }

    &-letter {
      display        : flex;
      align-items    : center;
      justify-content: center;
      position       : absolute;
      inset          : 0;

      padding-bottom: 24px;
      font-family   : theme('fontFamily.alt');
      font-size     : 300px;
      font-weight   : 900;
      line-height   : 300px;
      color         : #4C87E0;
      text-transform: uppercase;


      mask: repeating-linear-gradient(165deg, #000, #000 8px, rgba(0, 0, 0, 0) 8px, rgba(0, 0, 0, 0) 10px) repeat top, 100% 10px;

    }

    &-content {
      display       : flex;
      flex-direction: column;
      position      : absolute;
      inset         : 0;
      padding-top   : 132px;
      text-align    : center;

      @media (max-width: theme('screens.lg')) {
        justify-content: center;
        padding-top    : 0;
      }
    }

    &-title {
      font-size     : 42px;
      font-weight   : 900;
      color         : #E8F262;
      text-transform: uppercase;
    }

    &-value {
      position: relative;
      color   : rgba(232, 242, 98, 0);

      &::before {
        content   : attr(data-value);
        position  : absolute;
        inset     : 0;
        color     : #E8F262;
        text-align: left;
      }
    }
  }
}

.floating-button {
  display      : flex;
  align-items  : center;
  position     : fixed;
  right        : 20px;
  bottom       : 20px;
  padding      : 0 16px;
  padding-right: 56px;
  background   : url("../img/floating-button-bg.jpg") no-repeat center / cover;
  border-radius: 100px;
  box-shadow   : 0 8px 20px 0 #FA7D8666;
  color        : #fff;
  cursor       : pointer;
  z-index      : 5;
  gap          : 12px;


  @media (max-width: theme('screens.lg')) {
    padding-right: 32px;
    padding-left : 10px;
  }
}